import React, { useEffect, useState } from 'react';
import Slider, { Slide } from '../../../_common/Slider';

import ProductImage from '../../ProductImage';
import ProductImageMap from './ProductImageMap';
import Tag from '../../../_common/Tag';
import { getPimValue } from '../../../../utils/utils';

export const ProductImages = ({
  imageMapProducts,
  product,
  selectedVariantSku,
  site,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mainSliderRef, setMainSliderRef] = useState(null);

  const images = product.images.sort((a, b) => (a.name > b.name ? 1 : -1));

  const mapSkuToIndex = (images) => {
    const skuImageUrlMap = images.reduce((acc, image) => {
      product.variants.forEach((variant) => {
        variant.images.forEach((variantImage) => {
          if (
            variantImage.url === image.url &&
            !acc[getPimValue(variant.sku, site)]
          ) {
            acc[getPimValue(variant.sku, site)] = image.url;
          }
        });
      });

      return acc;
    }, {});

    const skuIndexMap = Object.entries(skuImageUrlMap).reduce(
      (acc, [sku, imageUrl]) => {
        acc[sku] = images.findIndex((image) => image.url === imageUrl);
        return acc;
      },
      {}
    );

    return skuIndexMap;
  };

  useEffect(() => {
    const skuIndexMap = mapSkuToIndex(images);
    if (!skuIndexMap || typeof skuIndexMap[selectedVariantSku] === undefined) {
      return;
    }

    const index = skuIndexMap[selectedVariantSku];
    setActiveIndex(index);
  }, [selectedVariantSku]);

  useEffect(() => {
    if (!mainSliderRef?.current) return;
    mainSliderRef.current.swiper.slideTo(activeIndex);
  }, [activeIndex]);

  const previewParams = {
    centerInsufficientSlides: true,
    navigation: 'inline',
    slidesPerView: 8,
    injectStyles: [
      ':host .swiper-vertical > .swiper-wrapper { position: absolute !important; }',
    ],
    breakpoints: {
      768: {
        direction: 'vertical',
        slidesPerView: 4,
      },
    },
  };

  return (
    <>
      <Slider className="product__images" onLoad={setMainSliderRef}>
        {images.map((image) => (
          <Slide>
            <ProductImage
              key={image.url}
              image={image}
              imageMapProducts={imageMapProducts}
              sizes={[
                { query: '(max-width: 479px)', containerSize: '424px' },
                { query: '(max-width: 767px)', containerSize: '712px' },
                { query: '', containerSize: '559px' },
              ]}
            >
              {product.badge?.value?.en !== 'None' && (
                <Tag title={product.badge?.value?.en} />
              )}
              {Array.isArray(image.imageMap?.spots) && (
                <ProductImageMap
                  imageMap={image.imageMap}
                  imageMapProducts={imageMapProducts}
                  site={site}
                />
              )}
            </ProductImage>
          </Slide>
        ))}
      </Slider>
      <Slider
        className="product__images-preview"
        onClick={setActiveIndex}
        params={previewParams}
        parentHeightSelector=".product__images"
      >
        {images.map((image) => (
          <Slide>
            <ProductImage
              key={image.url}
              image={image}
              sizes={[{ query: '', containerSize: '150px' }]}
            />
          </Slide>
        ))}
      </Slider>
    </>
  );
};

export default ProductImages;
