import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Slider, { Slide } from '../../_common/Slider';

const ProductLifestylePhotos = ({ product }) => {
  const [lifestylePhotos, setLifestylePhotos] = useState([]);

  useEffect(() => {
    fetchLifestylePhotos();
  }, [product.baseShopId]);

  const fetchLifestylePhotos = async () => {
    const pickitResponse = await axios({
      method: 'GET',
      url: `/api/pickit/files/${product.series.value}`,
    }).then((res) => res.data);

    const yotpoResponse = await axios({
      method: 'GET',
      url: `/api/yotpo/albums/product/${product.baseShopId}`,
    }).then((res) => res.data);

    const merged = [...pickitResponse, ...yotpoResponse]
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .filter((img) => img.imageSrc || img.videoSrc);

    setLifestylePhotos(merged);
  };

  if (lifestylePhotos.length === 0) return null;

  const params = {
    centeredSlides: true,
    className: 'product__lifestyle-photos-slider',
    height: 500,
    navigation: 'inline',
    slidesPerView: 'auto',
    slideToClickedSlide: true,
  };

  const photoComp = (photo) => (
    <div key={photo.date} className="lifestyle-photo">
      {photo.type === 'image' ? (
        <img src={photo.imageSrc} />
      ) : (
        <video autoPlay controls loop muted>
          <source src={photo.videoSrc} type="video/mp4" />
        </video>
      )}
    </div>
  );

  const single = lifestylePhotos.length === 1;

  return (
    <div className={`product__lifestyle-photos`}>
      <h2 className="product__lifestyle-photos-title">See it in action</h2>

      {single ? (
        <div className="product__lifestyle-photos-slider lifestyle-photos-slider--single">
          {photoComp(lifestylePhotos[0])}
        </div>
      ) : (
        <Slider params={params}>
          {lifestylePhotos.map((photo) => (
            <Slide>{photoComp(photo)}</Slide>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ProductLifestylePhotos;
