import React, { Component } from 'react';
import Slider, { Slide } from '../../../_common/Slider';

import ProductImage from '../../ProductImage';
import { getPimValue } from '../../../../utils/utils';
import { NextArrow, PrevArrow } from '../images/Arrows';

class ProductColors extends Component {
  render() {
    const { onClick, product, site } = this.props;

    const colorImages = {};
    for (const variant of product.variants) {
      if (colorImages[getPimValue(variant.colorName)]) continue;
      if (!variant.images[0]) continue;
      colorImages[getPimValue(variant.colorName)] = variant.images[0];
    }

    const images = Object.keys(colorImages).map((colorImageKey) => (
      <Slide>
        <li
          className="color"
          key={colorImageKey}
          onClick={(e) => onClick(e, colorImageKey)}
        >
          <span className="color-image">
            <ProductImage
              image={{
                alt: `${getPimValue(product.name, site)} - ${colorImageKey}`,
                url: colorImages[colorImageKey]?.url,
              }}
              sizes={[{ query: '', containerSize: '150px' }]}
            />
          </span>
          <span className="color-text">
            {colorImageKey.split('/').join(' / ')}
          </span>
        </li>
      </Slide>
    ));

    if (images.length === 0) return null;

    const params = {
      centerInsufficientSlides: true,
      className: 'colors-list',
      navigation: 'inline',
      slidesPerView: 3,
      breakpoints: {
        560: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 6,
        },
        1200: {
          slidesPerView: 7,
        },
        1600: {
          slidesPerView: 8,
        },
      },
    };

    return (
      <section className="product__colors">
        <div className="colors-wrapper">
          <Slider params={params}>{images}</Slider>
        </div>
      </section>
    );
  }
}

export default ProductColors;
