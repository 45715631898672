import React, { Component } from 'react';
import Slider, { Slide } from '../../../_common/Slider';

import anatomyIconsMapping from './anatomyIconsMapping';
import { getPimValue } from '../../../../utils/utils';
import { NextArrow, PrevArrow } from '../images/Arrows';

class ProductAnatomyIcons extends Component {
  render() {
    const { product, site } = this.props;

    const needlePoints = ['AlphaPoint', 'UltraPoint'];

    const icons = [];

    for (const iconKey of Object.keys(anatomyIconsMapping)) {
      const value = product[iconKey];

      if (!value?.value) continue;
      if (!anatomyIconsMapping[iconKey][value.key]) continue;

      const iconSet = anatomyIconsMapping[iconKey][value.key].icon.filter(
        (icon) => icon
      );

      if (iconSet.length === 0) continue;

      icons.push(
        ...iconSet.map((icon, i) => (
          <Slide>
            <li className="anatomy-icon" key={iconKey}>
              <div className="anatomy-icon-icon-wrapper">
                <span className="anatomy-icon-icon" key={`${iconKey}-${i}`}>
                  {icon}
                </span>
              </div>
              <span className="anatomy-icon-description">
                {i > 0 && needlePoints.includes(value.key)
                  ? 'Needle Point'
                  : getPimValue(product[iconKey], site)}
              </span>
            </li>
          </Slide>
        ))
      );
    }

    if (icons.length === 0) return null;

    const params = {
      centerInsufficientSlides: true,
      className: 'anatomy-icons-list',
      navigation: 'inline',
      slidesPerView: 3,
      breakpoints: {
        560: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 6,
        },
        1200: {
          slidesPerView: 7,
        },
        1600: {
          slidesPerView: 8,
        },
      },
    };

    return (
      <section className="product__anatomy-icons">
        <div className="anatomy-icons-wrapper">
          <Slider params={params}>{icons}</Slider>
        </div>
      </section>
    );
  }
}

export default ProductAnatomyIcons;
